
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Post',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getTopicPath: function(itemid: any){
			return "/topic/" + itemid;
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : "";
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.name || 'Inbox');
    const matchedFolder = computed(() => route.params.name);
    const topics = ref({});
	const { token, getRichText, doLike } = useDataService();
	
    watch(matchedFolder, () => {
		folder.value = matchedFolder.value as string;
		axios.get('https://beon.fun/api/v1/forum/'+route.params.name,{
			headers: {
				Authorization: 'Bearer '+token.value,
			}})
		.then(response => (
			topics.value = response.data.topics,
			folder.value =  response.data.forum.title
		));
    })
	
	axios.get('https://beon.fun/api/v1/forum/'+route.params.name,{
	headers: {
		Authorization: 'Bearer '+token.value,
	}})
    .then(response => (
		topics.value = response.data.topics,
		folder.value =  response.data.forum.title
	));
    return { folder, topics, route, getRichText, doLike }
  }
}
