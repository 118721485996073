<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
	<div id="container">
         <ion-list>
			<ion-item v-bind:router-link="route.path + '/new'">Добавить новую запись</ion-item>
            <ion-item class="ion-no-padding" v-for="item in topics" v-bind:key='item.id'>
                <div class="post">
                    <div class="post__header">
                        <span class="post__title">{{item.title}}</span><span class="post__date">{{item.created_at}}</span>
                    </div>
                    <div class="post__body">
                        <div class="post__author" @click="() => $router.push(getUserPath(item.user))">
                            <img v-bind:src="getUserAvatar(item.user)">
                            <div class="post__username">{{item.user.nickname}}</div>
                            <div class="post__sign">{{item.user.sign}}</div>
                        </div>
                        <div class="post__text" v-html="getRichText(item.text)" @click="() => $router.push('/topic/'+item.id)">
                        </div>
                    </div>
					<div class="like-wrapper" :class="{liked: item.liked.length}" @click="doLike(item, 'topic')">
						<fa icon="heart" class="fas-icon"></fa>
					</div>
					<div class="post__commcount">Комментариев: {{item.comments_count}}</div>
                </div>
            </ion-item>
          </ion-list>
      </div>
	
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post {
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
.post__commcount {
	text-align: right;
}
.like-wrapper {
	margin-top: 1em;
}
.like-wrapper.liked {
	color: red;
}
.fas-icon, .editools span {
	height: 1.5rem;
	font-size: 1.5rem;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Post',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getTopicPath: function(itemid: any){
			return "/topic/" + itemid;
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : "";
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.name || 'Inbox');
    const matchedFolder = computed(() => route.params.name);
    const topics = ref({});
	const { token, getRichText, doLike } = useDataService();
	
    watch(matchedFolder, () => {
		folder.value = matchedFolder.value as string;
		axios.get('https://beon.fun/api/v1/forum/'+route.params.name,{
			headers: {
				Authorization: 'Bearer '+token.value,
			}})
		.then(response => (
			topics.value = response.data.topics,
			folder.value =  response.data.forum.title
		));
    })
	
	axios.get('https://beon.fun/api/v1/forum/'+route.params.name,{
	headers: {
		Authorization: 'Bearer '+token.value,
	}})
    .then(response => (
		topics.value = response.data.topics,
		folder.value =  response.data.forum.title
	));
    return { folder, topics, route, getRichText, doLike }
  }
}
</script>